<template>
    <page-header-wrapper>
        <template slot="extra">
            <div class="btn_excel" @click="exportData"><img src="../../assets/images/excel.png" alt="">导出</div>
        </template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper" v-action:query>
                <a-form layout="inline">
                    <a-row>
                        <a-col :span="24">
                            <div style="display:flex;">
                                <span style="min-width: 44px;padding-top:6px;">筛选:</span>
                                <a-select v-model="queryParam.score" style="width: 100px;margin-right:10px">
                                    <a-select-option :value="item.id" v-for="(item, index) in scoreData" :key="index">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                                <a-select v-model="queryParam.business_name" style="width: 190px;margin-right:10px">
                                    <a-select-option :value="index" v-for="(item, index) in bussinessData" :key="index">
                                        {{ item }}
                                    </a-select-option>
                                </a-select>
                                <a-range-picker valueFormat="YYYY-MM-DD" v-model="queryParam.time"
                                    style="width:245px;margin-right:10px" />
                                <a-input style="width: 160px;" placeholder="请输入" v-model="queryParam.keywords" />
                                <span class="table-page-search-submitButtons" style="margin-left:10px;">
                                    <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                                </span>
                            </div>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData"
                showPagination="auto" :scroll="{ x: '100%' }">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="item" slot-scope="item,record">
                    <span class="btn_btn" @click="getInfo(record.id)"
                        v-if="record.business_type == 'Jmh_auditGshjyssq' || record.business_type == 'Jmh_auditSgsbbj' || record.business_type == 'Jmh_auditWtsjsq'">[查看]</span>
                    <span v-else>-</span>
                </span>
                <span slot="customer_name" slot-scope="customer_name">
                    {{ customer_name }}
                </span>
                <span slot="score" slot-scope="score">
                    <div class="star_main star_main1">
                        <img src="../../assets/images/star1.png" alt="" v-for="(data, idx) in Number(score)" :key="idx">
                        <img src="../../assets/images/star.png" alt="" v-for="(data, idx) in 5 - Number(score)"
                            :key="idx + 5">
                    </div>
                </span>
                <span slot="business_name" slot-scope="business_name">
                    {{ business_name }}
                </span>
                <span slot="create_time" slot-scope="create_time">
                    {{ create_time | time }}
                </span>
            </s-table>

            <a-drawer title="问卷项" placement="right" :visible="visible" @close="onClose" width="420px">
                <div class="ant-menu-root" style="min-height:86vh;overflow-y: auto;" v-if="answer.length > 0">
                    <div class="ques_list" v-for="(data, idx) in answer" :key="idx">
                        <div class="ques_list_title">{{ idx + 1 }}.{{ data.question }}</div>
                        <div v-if="data.type == 1" class="ques_list_main">
                            <a-radio-group :defaultValue="Number(data.answer[0])">
                                <a-radio :value="item.value" v-for="(item, index) in data.list" :key="index" disabled>
                                    {{ item.desc }}
                                </a-radio>
                            </a-radio-group>
                        </div>
                        <div v-else-if="data.type == 2" class="ques_list_main">
                            {{ data.answer[0] }}
                        </div>
                        <div v-else-if="data.type == 3" class="ques_list_main">
                            <a-checkbox-group :defaultValue="data.answer | getArr">
                                <a-checkbox :value="item.value" v-for="(item, index) in data.list" :key="index" checked
                                    disabled>
                                    {{ item.desc }}
                                </a-checkbox>
                            </a-checkbox-group>
                        </div>
                    </div>
                    <div class="ques_list">
                        <div class="ques_list_title">{{ answer.length + 1 }}.请对本次服务进行评分</div>
                        <div class="ques_list_main">
                            <div class="star_main">
                                <img src="../../assets/images/star1.png" alt="" v-for="(data, idx) in Number(score)"
                                    :key="idx">
                                <img src="../../assets/images/star.png" alt="" v-for="(data, idx) in 5 - Number(score)"
                                    :key="idx + 5">
                            </div>
                        </div>
                    </div>
                    <div class="ques_list">
                        <div class="ques_list_title">{{ answer.length + 2 }}.您对我们还有什么建议？</div>
                        <div class="ques_list_main">
                            {{ remark || '-' }}
                        </div>
                    </div>
                </div>
            </a-drawer>

        </a-card>
    </page-header-wrapper>
</template>

<script>
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
import moment from 'moment'
import { Modal, message } from 'ant-design-vue'
import {
    STable,
    Ellipsis
} from '@/components'
import {
    getList,
    getInfo,
    getBussinessType
} from '@/api/ques'

const columns = [
    {
        title: '序号',
        scopedSlots: {
            customRender: 'serial'
        },
        align: 'center',
        width: '80px'
    },
    {
        title: '用户',
        dataIndex: 'customer_name',
        width: '120px',
        scopedSlots: {
            customRender: 'customer_name'
        },
        align: 'center'
    },
    {
        title: '业务',
        dataIndex: 'business_name',
        width: '150px',
        scopedSlots: {
            customRender: 'business_name'
        },
        align: 'center'
    },
    {
        title: '问卷项',
        dataIndex: 'item',
        align: 'center',
        width: '100px',
        scopedSlots: {
            customRender: 'item'
        }
    },
    {
        title: '评分',
        dataIndex: 'score',
        align: 'center',
        width: '180px',
        scopedSlots: {
            customRender: 'score'
        }
    },
    {
        title: '提交时间',
        dataIndex: 'create_time',
        width: '160px',
        scopedSlots: {
            customRender: 'create_time'
        },
        align: 'center'
    }
]


export default {
    name: '',
    components: {
        STable,
        Ellipsis
    },
    data() {
        this.columns = columns
        return {
            visible: false,
            advanced: false,
            // 查询参数
            queryParam: {
                keywords: '',
                score: '0',
                time: [],
                start_time: '',
                end_time: '',
                business_name: '0'
            },
            // 加载数据方法 必须为 Promise 对象
            loadData: parameter => {
                //console.log('parameter',parameter)
                const requestParameters = Object.assign({}, parameter, this.queryParam)
                //console.log('requestParameters',requestParameters)
                requestParameters.start_time = this.queryParam.time[0] || ''
                requestParameters.end_time = this.queryParam.time[1] || ''
                return getList(requestParameters)
                    .then(res => {
                        return res.data
                    })
            },
            scoreData: [
                { id: '0', name: '全部' },
                { id: '1', name: '1分' },
                { id: '2', name: '2分' },
                { id: '3', name: '3分' },
                { id: '4', name: '4分' },
                { id: '5', name: '5分' }
            ],
            answer: [],
            remark: '',
            score: 0,
            bussinessData: [],
        }
    },
    mounted() {
        getBussinessType({}).then(res => {
            console.log('res=', res);
            if (res.code == 200) {
                this.bussinessData = Object.assign({ '0': '全部业务' }, res.data);
            }
        })
    },
    methods: {
        exportData() {
            const url = process.env.VUE_APP_API_BASE_URL + '/newAdmin.php?c=Feedback&a=export&score=' + this.queryParam.score + '&keywords=' + this.queryParam.keywords + '&token=' + storage.get(ACCESS_TOKEN)
            window.open(url)
        },
        getInfo(id) {
            const params = {
                id
            }
            getInfo(params).then(res => {
                if (res.code == 200) {
                    this.answer = res.data.answer
                    this.remark = res.data.remark
                    this.score = res.data.score
                    this.visible = true
                }
            }).catch(res => {

            })
        },
        onClose() {
            this.visible = false
        },
        reset() { //重置
            this.queryParam.score = '0'
            this.queryParam.keywords = ''
            this.queryParam.time = []
            this.queryParam.start_time = ''
            this.queryParam.end_time = ''
            this.queryParam.business_name = '0'
            this.$refs.table.refresh(true)
        }
    },
    filters: {
        time(t) {
            if (t) {
                return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
            } else {
                return t
            }
        },
        getArr(arr) {
            arr = arr.map(item => {
                return Number(item)
            })
            return arr
        }
    }
}
</script>


<style scoped>
.ques_list {
    margin-bottom: 15px;
}

.ques_list_title {
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
}

.star_main {
    display: flex;
    justify-content: center;
}

.star_main img {
    width: 32px;
    margin-right: 10px;
}

.star_main1 img {
    width: 24px;
}

.ques_list_main {
    padding-left: 20px;
}

.btn_excel {
    width: 78px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn_excel img {
    width: 18px;
    margin-right: 5px;
}
</style>
<style>
.ques_list_main .ant-checkbox-disabled+span {
    color: #333;
}

.ques_list_main .ant-checkbox-wrapper {
    margin-left: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.score_search .ant-form-item-label {
    min-width: 60px 
}
</style>