import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'

const api = {
	getList: (isProd ? '' : '/api') + '/newAdmin.php?c=Feedback&a=pageList',
  getInfo: (isProd ? '' : '/api') + '/newAdmin.php?c=Feedback&a=info',
  getBussinessType: (isProd ? '' : '/api') + '/newAdmin.php?c=Feedback&a=getbusiness',
}


export function getList(parameter) {
	return request({
		url: api.getList,
		method: 'post',
		data: parameter
	})
}


export function getInfo(parameter) {
	return request({
		url: api.getInfo,
		method: 'post',
		data: parameter
	})
}


export function getBussinessType(parameter) {
	return request({
		url: api.getBussinessType,
		method: 'post',
		data: parameter
	})
}
